import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
    0%{
        opacity: 0;
        visibility: hidden;
    }
    100%{
        opacity:1;
        visibility: visible;
    }
`

export const FormPopupContainerStyled = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${fadeIn} .3s forwards;
`

export const FormPopupStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #1c174c;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    max-width: 500px;
    padding: 24px 30px;

    & p{
        margin-bottom: 0;
        color: #1c174c;
        text-align: center;
    }

    & p:first-child, & p:nth-child(2){
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
    }
    & p:first-of-type{
        color: #ff5670;
    }

    & p:last-of-type{
        margin: ${(props) => props.error ? ' 0 0 24px' : ' 24px 0'};
    }
`