import React from "react";
import styled from "styled-components";
export default function Tag({ text }) {
   return <TagContent className="mb-4">{text}</TagContent>;
}
const TagContent = styled.button`
   background: #1c174c;
   border-radius: 4px;
   padding: 4px 16px;
   font-weight: 400;
   font-size: 10px;
   color: white;
   pointer-events: none;
`;
