import { Field, ErrorMessage } from 'formik'
import { InputContainer } from "./input.styles"
import { useTranslation } from 'next-i18next'
import Image from 'next/image'

const CustomTextarea = ({ field, form, ...props }) => {
    return <textarea {...field} {...props} rows={4} className="w-full p-3 outline-none font-normal" placeholder='Wiadomość' />
}

const Input = ({ name, placeholder, icon, type }) => {
    const { t } = useTranslation("homepage");

    return (
        <div className="my-2">
            <InputContainer>
                <div className="w-12 h-full flex justify-center pt-3.5">
                    <Image src={icon} alt="icon" className="h-5" />
                </div>
                {
                    type === 'textarea' ?
                        <Field name={name} id="answer" component={CustomTextarea} />
                        :
                        <Field name={name} className="w-full p-3 outline-none font-normal" placeholder={placeholder} />
                }
            </InputContainer>
            <ErrorMessage name={name} >{msg => <div className="text-xs text-red-400" >{t(msg)}</div>}</ErrorMessage>
        </div>
    )
}

export default Input