import Button from "../Button"
import { FaBug } from 'react-icons/fa'
import { FormPopupContainerStyled, FormPopupStyled } from "./form_popup.styles"
import { useTranslation } from 'next-i18next'
import parse from "html-react-parser"

const FormPopup = ({ type, hidePopup }) => {
    const { t } = useTranslation('homepage')
    const messages = t('form_message', { returnObjects: true })
    return (
        <FormPopupContainerStyled>
            {
                type === 'success' ?
                    <FormPopupStyled>
                        <p>{messages.success_header_1}</p>
                        <p>{messages.success_header_2}</p>
                        <p>{messages.success_paragraph}</p>
                        <div className="px-20 w-full">
                            <Button text={'OK'} fullWidth={true} onClick={hidePopup} />
                        </div>
                    </FormPopupStyled>
                    :
                    <FormPopupStyled error={true}>
                        <FaBug className="text-6xl" />
                        <p>{messages.fail_header_1}</p>
                        <p>{parse(messages.fail_paragraph)}</p>
                        <div className="px-20 w-full">
                            <Button text={'OK'} fullWidth={true} onClick={hidePopup} />
                        </div>
                    </FormPopupStyled>
            }

        </FormPopupContainerStyled>
    )
}

export default FormPopup