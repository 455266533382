import styled from "styled-components";

export const PaginationContainerStyled = styled.div`
   width: 100%;
   justify-content: center;
   display: none;

   @media (max-width: 1024px) {
      display: flex;
   }
`;
