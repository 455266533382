import styled from "styled-components";
import { Formik, Form as FormikForm, Field } from "formik";

export const FormContainerStyled = styled.div`
    border-radius: 16px;
    overflow: hidden;
    padding: 5px;
`

export const FormStyled = styled(FormikForm)`
    border: 1px solid #1c174c;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 28px;
`;

export const CheckboxStyled = styled(Field)`
   position: relative;
   width: 2em;
   background-color: white;
   height: 2em;
   color: white;
   border: 1px solid ${(props) => (props.error ? "#ff5670" : "#1c174c")};
   border-radius: 10px;
   appearance: none;
   outline: 0;
   flex-shrink: 0;
   cursor: pointer;
   transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

   ::before {
      position: absolute;
      content: "";
      display: block;
      top: 6px;
      left: 11px;
      width: 8px;
      height: 14px;
      border-style: solid;
      border-color: black;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
   }
   :checked {
      color: white;
      border-color: #e1e1e1;
      background: #ff5670;
      ::before {
         opacity: 1;
      }
      ~ label::before {
         clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
   }
`;
