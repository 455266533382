import React, { useState } from "react";
import styled from "styled-components";
import Button from "../Button";
import { Formik, Form as FormikForm, Field } from "formik";
import Input from "./input/input";
import * as Yup from "yup";
import parse from "html-react-parser";
import person from "../../public/images/icons/person.png";
import at from "../../public/images/icons/at.png";
import attachment from "../../public/images/icons/attachment.png";
import building from "../../public/images/icons/building.png";
import comment from "../../public/images/icons/comment.png";
import FileInput from "./file_input/file_input";
import axios from 'axios'
import FormPopup from "../form_popup/form_popup";
import { CheckboxStyled, FormContainerStyled, FormStyled } from "./form-component.styles";
import { useTranslation } from 'next-i18next'
// import { Form, Checkbox } from "./form-component.styles";
// Przy językach te hard coded errory trzeba zmienić na klucze z obieku z errorami
const formSchema = Yup.object().shape({
   name: Yup.string().required("field_required"),
   email: Yup.string().email("email_incorrect").required("field_required"),
   company: Yup.string().required("field_required"),
   message: Yup.string(),
   policy: Yup.bool().oneOf([true], "Zaznacz zgodę"),
   file: Yup.mixed(),
});

const FormComponent = ({ data }) => {
   const { t } = useTranslation("homepage");
   const [loading, setLoading] = useState(false)
   const [emailState, setEmailState] = useState('start')

   const labels = t('form_fields', { returnObjects: true })

   const hidePopup = () => {
      setEmailState('start')
   }

   return (
      <Formik
         validationSchema={formSchema}
         onSubmit={async (values, { resetForm }) => {
            setLoading(true)
            const fd = new FormData()
            fd.append('name', values.name)
            fd.append('email', values.email)
            fd.append('company', values.company)
            fd.append('message', values.message)
            fd.append('file', values.file)
            // TODO: Wysyłka maila
            try {
               const { data, status } = await axios.post('/api/hello', fd, {
                  headers: {
                     'Content-Type': 'multipart/form-data'
                  }
               })
               console.log(data);
               setEmailState('success')
               setLoading(false)
               resetForm()
            } catch (error) {
               console.log(error)
               setEmailState('error')
               setLoading(false)
            }

         }}
         initialValues={{
            name: "",
            email: "",
            company: "",
            message: "",
            policy: false,
            file: null,
         }}
      >
         {({ errors, touched }) => (
            <FormContainerStyled className="relative">
               <FormStyled>
                  <h3 className="text-center">{data.heading}</h3>
                  <p className="text-center">{data.paragraph}</p>
                  <Input name="name" placeholder={labels.name} icon={person} />
                  <Input name="email" placeholder={labels.email} icon={at} />
                  <Input name="company" placeholder={labels.company} icon={building} />
                  <Input name="message" type="textarea" placeholder={labels.message} icon={comment} />
                  {/* <Input name="file" error={touched.file && errors.file} placeholder="Załącznik" icon={attachment} /> */}
                  <FileInput name="file" placeholder={labels.attachment} icon={attachment} />
                  <div className="flex gap-4 text-start my-4">
                     <CheckboxStyled type="checkbox" name="policy" error={errors.policy && touched.policy} />
                     <label htmlFor="policy" className="text-sm">
                        {parse(data.policies)}
                     </label>
                  </div>
                  <Button text={labels.send} type="submit" fullWidth disabled={loading} />
               </FormStyled>
               {emailState !== 'start' && <FormPopup type={emailState} hidePopup={hidePopup} />}
            </FormContainerStyled>
         )}
      </Formik >
   );
};

export default FormComponent;

