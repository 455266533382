import Head from "next/head"
import { useTranslation } from "next-i18next";

export default function CustomHead() {
    const { t } = useTranslation("meta");
    
    console.log(t('title'))

    return (
        <Head>
            <title>{t('title')}</title>
            <meta name="description" content={t('description')} />
            <meta property="og:title" content={t('title')} />
            <meta property="og:description" content={t('description')} />
            <meta property="og:image" content="/og_image.png" />
        </Head>
    )
}
